export default {
  hotels: 'hotels',
  transfers: 'transfers',
  excursions: 'excursions',
  'booking': 'booking',
  'Booking': 'Booking',
  'Agent Log in': 'Agent Log In',
  WE_CONNECT_THE_WORLD: 'WE CONNECT THE WORLD!',
  'Our B2B best hotel booking platform is a complete online hotel solution for span travel agents and corporate clients.': 'Our B2B best hotel booking platform is a complete online hotel solution for span travel agents and corporate clients.',
  'Hotels in the world': 'Hotels in the world',
  'Hotels in Russia': 'Hotels in Russia',
  'Destination countries': 'Destination countries',
  'Transfers routes': 'Transfers routes',
'Excursions': 'Экскурсии',
'About us': 'About us',
'Log in': 'Log in',
'Forgot the password?': 'Forgot the password?',
'Register with Mybookoffice!': 'Register with Mybookoffice!',
'Sign up and enjoy competitive rates and exclusive conditions on hotels, vacation rentals, transfers, and excursions.': 'Sign up and enjoy competitive rates and exclusive conditions on hotels, vacation rentals, transfers, and excursions.',
'Top up by the amount:': 'Top up by the amount:',
'It s important to know!': 'It s important to know!',
'c': 'c',
'to': 'to',
'Wishes:': 'Wishes:',
'Enter your wishes...': 'Enter your wishes...',
'Enter a name': 'Enter a name',
'Placement data:': 'Placement data:',
'User data:': 'User data:',
'Adult': 'Adult',
'Сhildren': 'Сhildren',
'Age': 'Age',
'First name': 'First name',
'Last name': 'Last name',
'Total:': 'Total:',
'adults': 'adults',
'Adults:': 'Adults:',
'children s': 'children s',
'Children:': 'Children:',
'nights': 'nights',
'Nights:': 'Nights:',
'rooms': 'rooms',
'Rooms:': 'Rooms:',
'Do you really want to delete the record?': 'Do you really want to delete the record?',
'No, continue booking': 'No, continue booking',
'Yes, delete the order': 'Yes, delete the order',
'The record was successfully deleted': 'The record was successfully deleted',
'Do you really want to cancel your reservation?': 'Do you really want to cancel your reservation?',
'Cancel': 'Cancel',
'Invoice': 'Invoice',
'Voucher': 'Voucher',
'Cancellation Policy and cost:': 'Cancellation Policy and cost:',
'Non-refundable': 'Non-refundable',
'after': 'after',
'No': 'No',
'Yes': 'Yes',
'Ok': 'Yes',
'Free Wi-Fi': 'Free Wi-Fi',
'Free parking': 'Free parking',
'Fireplace': 'Fireplace',
'Small pets (up to 5 kg) are allowed': 'Small pets (up to 5 kg) are allowed',
'Cafe': 'Cafe',
'Spa center': 'Spa center',
'Laundry': 'Laundry',
'Animals': 'Animals',
'Hair': 'Hair',
'Restaurant': 'Restaurant',
'Nearest address:': 'Nearest address:',
'City/District/Name of the hotel': 'City/District/Name of the hotel',
'From date:': 'From date:',
'Before the date:': 'Before the date:',
'Exit': 'Exit',
'More': 'More',
'photo': 'photo',
'wrote a review': 'wrote a review',
'Date of stay:': 'Date of stay:',
'Expires after:': 'Expires after:',
'Balance': 'Balance',
'Replenishment of the balance': 'Replenishment of the balance',
'Replenish': 'Replenish',
'Personal': 'Personal',
'Credit': 'Credit',
'Add a payment method': 'Add a payment method',
'To pay the bill, use the card or contact by phone or E-mail.': 'To pay the bill, use the card or contact by phone or E-mail.',
'The transaction was successful! Funds have been successfully credited to the account': 'The transaction was successful! Funds have been successfully credited to the account',
'The transaction failed! Try to repeat the operation a little later': 'The transaction failed! Try to repeat the operation a little later',
'Done': 'Done',
'Confirmed': 'Confirmed',
'Cancelled': 'Cancelled',
'There are no confirmed bookings': 'There are no confirmed bookings',
'There are no cancelled bookings': 'There are no cancelled bookings',
'Basket': 'Basket',
'To access the shopping cart, add at least one room': 'To access the shopping cart, add at least one room',
'Orders in the shopping cart:': 'Orders in the shopping cart:',
'Clean the basket': 'Clean the basket',
'Do you really want to delete orders?': 'Do you really want to delete orders?',
'The trash was successfully emptied': 'The trash was successfully emptied',
'Booking completed successfully! You will now be moved to the booking page': 'Booking completed successfully! You will now be moved to the booking page',
'Please fill in the main fields of the form': 'Please fill in the main fields of the form',
'The booking was made with an error! Try again later': 'The booking was made with an error! Try again later',
'Age of children': 'Age of children',
'Popular amenities': 'Popular amenities',
'Reviews': 'Reviews',
'Unfortunately, there were no available hotels under the specified conditions. Change the search terms!': 'Unfortunately, there were no available hotels under the specified conditions. Change the search terms!',
'Change the search': 'Change the search',
'penalty': 'penalty',
'Enter the address': 'Enter the address',
'Room': 'Room',
'Type of placement': 'Type of placement',
'Number of stars': 'Number of stars',
'Additionally': 'Additionally',
'In order to ensure the correct functioning of the site, we use cookies. We use some of the cookies to analyze and improve the user experience. You can find more detailed information on the Privacy Policy and Cookie Policy pages. If you agree to improve the user experience, click "Allow"': 'In order to ensure the correct functioning of the site, we use cookies. We use some of the cookies to analyze and improve the user experience. You can find more detailed information on the Privacy Policy and Cookie Policy pages. If you agree to improve the user experience, click "Allow"',
'Allow': 'Allow',
'Reset everything': 'Reset everything',
'Show on map': 'Show on map',
'Additional filters': 'Additional filters',
'Prices and Offers': 'Prices and Offers',
'Show more ...': 'Show more ...',
'Search': 'Search',
'Hotel': 'Hotel',
'Apartotel': 'Apartotel',
'The Motel': 'The Motel',
'Villa': 'Villa',
'Rural hotel': 'Rural hotel',
'Guest house': 'Guest house',
'Hostel': 'Hostel',
'Historic hotel': 'Historic hotel',
'Apartments': 'Apartments',
'Camping': 'Camping',
'Add to Cart': 'Add to Cart',
'Time left': 'Time left',
'* - paid services': '* - paid services',
'There are no options with the specified price': 'There are no options with the specified price',
'from': 'from',
'You cannot book multiple rooms of the same type. Select a different type of number': 'You cannot book multiple rooms of the same type. Select a different type of number'
};